@import "./_variable.css";
@import "./CreateAds.css";
@import "./stripe.css";
@import "./notifications.css";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

.user-Wrapper {
    background-color: var(--backgroundPrimary);
    min-height: 100vh;
    overflow-x: hidden;
    font-family: 'Inter', sans-serif;
}

.helper__field {
    font-size: 13px;
    color: #333;
    font-weight: 600;
}

.user-Wrapper.rtl {
    direction: rtl !important;
}

.container {
    max-width: 1500px;
    margin: auto;
}

.button-primary {
    background: conic-gradient(from 180deg at 50% 50%, #5CC6E0 0deg, #F7A166 256.87deg, #5CC6E0 360deg);
    
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    color: #000;
    font-weight: 400;
    font-size: 15px;
    border-radius: 10px;
    padding: 10px 20px;
    outline: none;
    border: none;
    cursor: pointer;
}

.loading-button {
    width: 25px;
    height: 25px;
    background: transparent;
    border: 3px solid transparent;
    border-top-color: #fff;
    border-bottom-color: #fff;
    border-radius: 50%;
    animation: rotate .5s linear infinite;
    color: #000;
    font-weight: 400;
    font-size: 18px;
}






.select.home,
.select.login {
    width: 100% !important;

}

.select-box {
    height: 50px !important;
}

:is(.select-box, .select.home, .select.login) .css-1s2u09g-control {
    border-radius: 10px !important;

    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.select-box.error .css-1s2u09g-control {
    border: 1px solid red;
}


.card {
    min-height: 300px;
    height: 100%;
    width: 100%;
    background: #fff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 10px;
}

.card img {
    flex: 1;
    border-radius: 30px;
    max-height: 300px;
    height: 100%;
    width: 100%;
}

.card .content {
    flex: 1;
    background: linear-gradient(180deg, #5CC6E0 0%, rgba(227, 162, 99, 0.25) 100%);
    width: 50%;
    border-radius: 30px;
    max-height: 300px;
    height: 100%;
    padding: 30px 5px;
    display: grid;
    place-items: center;


}

.card .content .flex-row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    align-self: flex-end;

}

.card .content .flex-row button {
    min-width: 150px;
    font-weight: 500;
    font-size: 14px;
    text-transform: capitalize;
}

.card .content .flex-row button:active {
    animation: scale-button .3s ease 1;
}

.card .image-wrapper {
    width: 50%;
    min-height: 300px;
    height: 100%;
    display: grid;
    place-items: center;
    position: relative;
}

.card .image-wrapper img {
    object-fit: cover;
}

.card .image-wrapper .absolute {
    position: absolute;
    inset: 0;
    display: grid;
    place-items: center;
}

@keyframes scale-button {
    to {
        transform: scale(1.2);
    }
}

.card .content table {
    margin: auto;
  
}

.card .content :is(tr, td, table) {
    padding-block: 8px;
    padding-inline: 15px;
    word-break: break-all;
    color: #000;
    font-size: 20px;
    font-weight: 500;
}

.card .content td {
    min-width: 100px;
}

@keyframes rotate {
    to {
        transform: rotate(180deg);
    }
}

.select-container>div {
    width: 100% !important;
}

.slick-track {
    margin: auto !important;
}

.slick-slider,
.slick-initialized {
    max-width: 500px !important;
    width: 500px !important;

}


.box-slider {
    width: fit-content !important;
    margin: auto;
    height: 80px;
    background-color: #fff;
}

.slick-slide,
.slick-active {
    padding-inline: 10px;
}
.slick-slide >div{
    display: grid;
}
/* .detail-post .slick-slide >div{
    overflow: hidden !important;
} */
@media screen and (max-width:1500px) {
    .container {
        max-width: 1200px;
    }
}

@media screen and (max-width:1250px) {
    .container {
        max-width: 98%;
    }

  
}

@media screen and (max-width:991px) {



    .slick-slider,
    .slick-initialized {
        width: 300px !important;
    }

    .card .content :is(tr, td, table) {
        font-size: 16px;
    }

    .card .flex-row {
        gap: .5rem;
    }

    .card .content .flex-row button {
        font-size: 13px;
        padding-inline: 4px;
    }
}

@media screen and (max-width:768px) {
    .button-primary {
        font-size: 15px;
    }


    .card {
        padding: 5px;
        gap: 5px;
        min-height: 250px;
        border-radius: 15px;
        overflow: hidden;
    }

    .card .content {

        min-height: 250px;
        border-radius: 15px;

    }

    .card .image-wrapper {
        min-height: 250px;
        border-radius: 15px;
    }

    .card img {
        min-height: 250px;
        width: 100%;
        height: 100%;
        border-radius: 15px;
    }

    .card button {
        max-width: 150px;
        width: 100%;
        height: 40px;
        font-size: 12px;
    }

    .card .content :is(tr, td, table) {
        font-size: 14px;
        padding-block: 4px;
        padding-inline:10px;
    }

    .card .content .flex-row {
        flex-direction: column;
        gap: .8rem;
        padding-block: 5px;


    }

    .slick-slider,
    .slick-initialized {
        width: 500px !important;
        margin-block: 0.5rem;
    }

}

@media screen and (max-width:650px) {
      .slick-slider,
    .slick-initialized {
        width: 400px !important;

    }
    .card .content :is(tr, td, table) {
        padding-inline:4px;
    }
}

@media screen and (max-width:500px) {
    .card .content tr td:nth-child(2) {

        min-width: 50px;
        max-width: 70px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .card .content {
        padding-inline: 2px;
    }
    .card .content tr td:first-of-type {

        min-width: 70px !important;
        max-width: 70px !important;
    }

    .card button {
        width: 100%;
        height: 30px;
        font-size: 12px;
    }

    .button-primary {
        font-size: 13px;
    }





    .slick-slider,
    .slick-initialized {
        width: 200px !important;
    }
}

@media screen and (max-width:400px) {
    .card {
        flex-direction: column;
    }
    .card .content tr td:first-of-type {

        min-width: fit-content !important;
        max-width: fit-content !important;
    }

    .card .content {
        width: 100%;
        order: 2;
    }

    .card .image-wrapper {
        width: 100%;
        order: 1;
    }

    .hide-in-400 {
        display: none;
    }

   
}


.spinner-image {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 5px solid transparent;
    border-inline-color: #E5B07E;
    animation: rotate 1s linear infinite;
}

@keyframes rotate {
    to {
        transform: rotate(360deg);
    }
}