.wrapper-create {
    background: conic-gradient(from 180deg at 50% 50%, #5CC6E0 0deg, #F7A166 204.37deg, #5CC6E0 360deg);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 57px 93px;
}

.wrapper-create>.inside-wrapper {
    background: conic-gradient(from 180deg at 50% 50%, #5CC6E0 0deg, #E3A263 226.87deg, #5CC6E0 360deg);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 32px 70px;
}


.wrapper-create>.inside-wrapper .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}

.wrapper-create>.inside-wrapper .grid>div {
    display: flex;
    flex-direction: column;
    width: 100% !important;
    min-width: 100%;
    gap: 80px;
}

.wrapper-create>.inside-wrapper .grid>div>div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: 100%;
}

.wrapper-create>.inside-wrapper input {

    height: 50px;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    outline: none;
    border: none;
    border-radius: 10px;
    padding-inline: 1rem;
}

.wrapper-create>.inside-wrapper textarea {
    padding: 1rem;
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    outline: none;
    border: none;
    border-radius: 10px;
}

.wrapper-create>.inside-wrapper input::placeholder,
.wrapper-create>.inside-wrapper textarea::placeholder {
    color: rgba(0, 0, 0, 0.5);
}


@media (max-width:1550px) {
    .wrapper-create {
        padding-inline: 73px;
    }

    .wrapper-create>.inside-wrapper {
        padding: 32px 50px;

    }
}
@media screen and (max-width:1000px) {

    .wrapper-create {
        padding-inline: 50px;
    }

    .wrapper-create>.inside-wrapper {
        padding: 32px 40px;

    }
}
@media (max-width:991px) {
    .wrapper-create {
        padding-inline: 53px;
    }

    .wrapper-create>.inside-wrapper {
        padding: 32px 30px;

    }
}

@media (max-width:600px) {
    .wrapper-create {
        padding-inline: 30px;
    }


}

@media (max-width:450px) {
    .wrapper-create {
        padding-inline: 13px;
    }

    .wrapper-create>.inside-wrapper {
        padding-inline: 15px;

    }

}