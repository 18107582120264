.notification-wrapper {
    max-width: 500px;
    min-width: 340px;
    max-height: 500px;
    min-height: 300px;
    width: 100%;
    height: 100%;
    background: conic-gradient(from 180deg at 50% 50%, #5CC6E0 0deg, #F7A166 204.37deg, #5CC6E0 360deg);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 100%;
    right: 0;
    margin-top: 1rem;
    z-index: 1000000;
    border-radius: 20px;
    padding-block: 10px;

}

.notification-wrapper::after {
    border-left: 20px solid transparent;
    border-top: 20px solid #E2A677;
    top: -5px;
    content: "";
    position: absolute;
    right: 20px;
    transform: rotate(-43deg);
}

.notification-wrapper .scroll {
    overflow: auto;
    height: 100%;
    padding-inline: 10px;
}

.notification-wrapper .scroll::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
}

.notification-wrapper .scroll::-webkit-scrollbar-thumb {
    background-color: hwb(0 87% 13% / 0.705);
    border-radius: 10px;
}

.notification-wrapper .notification {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    text-align: start;
    gap: .4rem;
    cursor: pointer;
}

.notification-wrapper .notification span {
    font-size: .8em;
}

@media screen and (max-width:500px) {
    .notification-wrapper {
        min-width: 300px;
    }
}

@media screen and (max-width:349px) {
    .notification-wrapper {
        position: relative;
        min-width: 250px;
        z-index: 1;
    }

    .notification-wrapper::after {
        display: none;
    }

    .notification-wrapper .scroll {
        height: 50vh;
    }
}