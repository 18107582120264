@import "../_variable.css";
.footer{
    margin-top:95px;
    min-height: 300px;
    background-color: var(--backgroundFooter);
    padding-bottom: 1rem;

}
.container{
    display: grid;
    padding-block: 55px;
    grid-template-columns: 1fr;
    grid-gap: 2rem;
}
.container > div{
    width: 100%;
    font-weight: 400;
    color: #000;
    display: grid;
    place-items: start center;
    text-align: center;
}
.container > div a{
    color: #000;
    text-decoration: none;
}
.container ul {
    width: fit-content;
    word-break: break-all;
    padding: 0px;
    list-style: none;
    margin-top: 20px;
    
    
}
.container ul li{
    margin-block: 5px;
}
@media screen and (min-width:350px) {
    .container{
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (min-width:768px) {
    .container{
        grid-template-columns: 1fr 1fr 1fr;
    }
}
@media screen and (min-width:1600px) {
    .container{
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
        grid-gap: 0;
    }
    .container> div{
        display: block;

        text-align: start;
    }
}

@media screen and (max-width:768px) {
    .container img{
        width:100px;
    }
}