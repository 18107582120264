.SelectWrap {
    max-width: 200px;
    height: 33px;
    position: relative;
    user-select: none;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25), inset 0px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
   
   
}

.default_option {
    background: #fff;
  
    border-radius: 5px;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 10px;
    justify-content: space-between;
 
}

.default_option input {
    height: 100% !important;
    
    width: 100% !important;
    padding-inline-start:1rem !important;
    border: none !important;
    outline: none !important;
    cursor: context-menu !important;
    border-radius: 10px;

}

.default_option input::placeholder {
    color: rgba(0, 0, 0, 0.5);
}

.SelectWrap .select_ul {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: #fff;
    border-radius: 5px;
    margin-top: 10px;
    max-height: 0px;
    height: fit-content;
    overflow-y: auto;

    transition: all .1s linear;
    z-index: 1000000000000000;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}

.SelectWrap .active {
    max-height: 200px;

    transition: all .1s linear;
}

.SelectWrap .select_ul::-webkit-scrollbar {
    width: 5px;
}

.SelectWrap .select_ul::-webkit-scrollbar-thumb {
    background: #F7A166;
}

.SelectWrap .select_ul div {
    margin-block: 2px;
    padding-block: 4px;
    min-height: 20px;
    padding-inline: 10px;
    cursor: pointer;

}

.SelectWrap .select_ul div:hover {
    background-color: rgba(245, 135, 62, .2)
}

.SelectWrap .select_ul div p {
    font-weight: 500;
    font-size: 14px;
}