.StripeElement {
    margin: 15px auto;
    padding: 10px 12px;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;
}

.StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
}