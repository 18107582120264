.container{
    display: flex ;
    align-items: center;
    gap:5px;
    padding-top: 25px;
    padding-bottom: 30px;

}
.list{
    list-style: none;
    margin-inline-start: auto;
    display: flex;
    align-items: center;
    gap:1.5rem;


}
:global(.openMenu){
    display: none !important;
}
.openMewnu{
    display: none;
}
.list li a{
    display: grid;
    place-items: center;
    text-align: center;
    font-size: 14px;
    color: #000;
    text-decoration: none;
}
.list li .active{
    color:#e5b07d !important;
}
.list li a span{
    display: block;
    margin-top: 5px;
    font-size: 13px;
    font-weight: 400;
}

@media screen and (max-width:991px) {
    :global(.openMenu){
        display: block !important;
    }
    .list{
        display: none;

    }
    .mobile_list{
        display:block;
    }

    .mobile_list ul {
        position: fixed;
        width: 0px;
        overflow: hidden;
        background-color: #fff;
        top: 0;
        left: 0;
        z-index: 10;

        height: 100vh;
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap:1.5rem;
        transition: all .3s ease;
    }
    .mobile_list .menuMobile  li {
        width: fit-content !important;
    }
    .menuMobile li a{
        display: grid;
        place-items: center;
        text-align: center;
        font-size: 14px;
        color: #000;
        text-decoration: none;
    }
    .menuMobile li .active{
        color:#e5b07d !important;
    }

}